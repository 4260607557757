// srx/services/tripService.ts

import { generateClient } from "aws-amplify/api";
import dayjs, { Dayjs } from "dayjs";
import { createTrip, deleteTrip, updateTrip } from "../graphql/mutations";
import { listTrips } from "../graphql/queries";
import { Stop, Task, Trip, TripWithId } from "../models/tripModels";
import { recommendedTripsJson } from "./recommendedTrips";

const client = generateClient({ authMode: "userPool" });

export const fetchTrips = async (): Promise<TripWithId[]> => {
  try {
    const result = await client.graphql({
      query: listTrips,
    });

    const unpackedTrips: TripWithId[] = result.data.listTrips.items.map((i) => {
      return {
        id: i.id,
        name: i.name,
        description: i.description,
        startDate: dayjs(i.startDate),
        stops: JSON.parse(i.stops!),
        tasks: JSON.parse(i.tasks!),
        deleted: i.deleted,
        shared: i.shared,
      };
    });

    unpackedTrips.sort((a, b) => a.startDate.diff(b.startDate));

    return unpackedTrips;
  } catch (e) {
    throw e;
  }
};

export const fetchRecommendedTrips = (): TripWithId[] => {
  const recommendedTrips = JSON.parse(recommendedTripsJson) as TripWithId[];
  //TODO: see if this is still necessary
  const recommendedTripsWithStartDate: TripWithId[] = recommendedTrips.map((t) => {
    return {
      ...t,
      startDate: dayjs(t.startDate),
    };
  });

  return recommendedTripsWithStartDate;
};

export const addNewTrip = async (trip: Trip) => {
  try {
    const variables = {
      input: {
        name: trip.name,
        description: trip.description,
        startDate: trip.startDate.format("YYYY-MM-DD"),
        stops: JSON.stringify(trip.stops),
        tasks: JSON.stringify(trip.tasks),
        deleted: false,
        shared: false,
      },
    };

    const response = await client.graphql({
      query: createTrip,
      variables: variables,
    });

    return response.data.createTrip.id;
  } catch (e) {
    throw e;
  }
};

export const updateTripDetails = async (
  id: string,
  name: string,
  startDate: Dayjs,
  shared: boolean,
  stops: Stop[],
  tasks?: Task[]
) => {
  try {
    const input: any = {
      id: id,
      name: name,
      startDate: startDate.format("YYYY-MM-DD"),
      shared: shared,
      stops: JSON.stringify(stops),
    };

    // Conditionally add tasks if provided
    if (tasks !== undefined) {
      input.tasks = JSON.stringify(tasks);
    }

    const variables = { input };

    await client.graphql({
      query: updateTrip,
      variables: variables,
    });
  } catch (e) {
    throw e;
  }
};

export const updateTripTasks = async (id: string, tasks: Task[]) => {
  try {
    const variables = {
      input: {
        id: id,
        tasks: JSON.stringify(tasks),
      },
    };

    await client.graphql({
      query: updateTrip,
      variables: variables,
    });
  } catch (e) {
    throw e;
  }
};

export const removeTrip = async (id: string) => {
  try {
    const variables = {
      input: {
        id: id,
      },
    };

    await client.graphql({
      query: deleteTrip,
      variables: variables,
    });
  } catch (e) {
    throw e;
  }
};
