// src/components/ViewTrip.tsx

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, STATUS, Step } from "react-joyride";
import { useNavigate, useParams } from "react-router-dom";
import { Stop, Task, Trip, TripWithId } from "../models/tripModels";
import { post } from "aws-amplify/api";
import dayjs, { Dayjs } from "dayjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ViewTripProps {
  trips: TripWithId[];
  handleUpdateTripDetails: (
    id: string,
    name: string,
    startDate: Dayjs,
    shared: boolean,
    stops: Stop[],
    tasks?: Task[]
  ) => void;
  handleUpdateTripTasks: (id: string, tasks: Task[]) => void;
  handleCreateTrip: (trip: Trip) => Promise<string>;
  handleDeleteTrip: (id: string) => void;
  isAuthenticated: boolean;
  hasRunRecommendedTour: boolean;
  setHasRunRecommendedTour: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTrip?: boolean;
}

const ViewTrip = ({
  trips,
  handleUpdateTripDetails,
  handleUpdateTripTasks,
  handleCreateTrip,
  handleDeleteTrip,
  isAuthenticated,
  hasRunRecommendedTour,
  setHasRunRecommendedTour,
  isFirstTrip = false,
}: ViewTripProps) => {
  const [trip, setTrip] = useState<TripWithId | undefined>(undefined);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();

  useEffect(() => {
    if (trips && trips.find((t) => t.id === id)) {
      setTrip(trips.find((t) => t.id === id));
    }
  }, [trips]);

  useEffect(() => {
    if (trip && isFirstTrip && !hasRunTour) {
      setRunTour(true);
      setHasRunTour(true);
    }

    if (trip?.id.substring(0, 11) === "recommended") {
      setIsDisabled(true);
    } else {
      setIsDisabled(!trips.find((t) => t.id === id));
    }
  }, [trip]);

  useEffect(() => {
    const fetchSharedTrip = async () => {
      const restOperation = await post({
        apiName: "sharedTripApi",
        path: `/sharedtrip/${id}`,
      });

      const data = await restOperation.response;
      const body: any = await data.body.json();

      const parsedTrip: TripWithId = {
        id: body.id,
        name: body.name,
        description: body.description,
        startDate: dayjs(body.startDate),
        stops: body.stops,
        tasks: body.tasks,
        deleted: body.deleted,
        shared: body.shared,
      };

      setTrip(parsedTrip);
    };

    if (!trips.find((t) => t.id === id)) {
      fetchSharedTrip();
    }
  }, []);

  let dayIndex = 0;

  //TRIP LOGIC

  const [isCopyTripModalOpen, setIsCopyTripModalOpen] = useState(false);
  const [isDeleteTripModalOpen, setIsDeleteTripModalOpen] = useState(false);
  const [isShareTripModalOpen, setIsShareTripModalOpen] = useState(false);
  const [newTripName, setNewTripName] = useState("");
  const [newTripNameError, setNewTripNameError] = useState("");

  const onNewTripNameChange = (newName: string) => {
    setNewTripName(newName);
    setNewTripNameError("");
  };

  const onEditTrip = async () => {
    navigate(`/edit/${trip!.id}`);
  };

  const onCopyTripOpen = () => {
    setIsCopyTripModalOpen(true);
    setNewTripName(`Copy of ${trip!.name}`);
    setNewTripNameError("");
  };

  const onCopyTripCancel = () => {
    setIsCopyTripModalOpen(false);
  };

  const onCopyTripConfirm = () => {
    if (!newTripName) {
      setNewTripNameError("Trip name must not be empty");
      return;
    }
    onCopyTrip();
    setIsCopyTripModalOpen(false);
  };

  const onCopyTrip = async () => {
    const newTrip: Trip = { ...trip!, name: newTripName };
    const newId = await handleCreateTrip(newTrip);
    navigate(`/trip/${newId}`);
  };

  const onDeleteTrip = () => {
    if (id) {
      handleDeleteTrip(id);
      navigate(`/`);
    }
  };

  const onAddRecommendedTrip = async () => {
    const newTrip: Trip = { ...trip! };
    const newId = await handleCreateTrip(newTrip);
    navigate(`/trip/new/${newId}`);
    setIsCopyTripModalOpen(false);
  };

  const onToggleShare = async () => {
    handleUpdateTripDetails(trip!.id, trip!.name, trip!.startDate, !trip!.shared, trip!.stops);
  };

  //TASK LOGIC

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [taskIndexBeingEdited, setTaskIndexBeingEdited] = useState(-1);
  const [newTaskDescription, setNewTaskDescription] = useState("");
  const [newTaskDescriptionError, setNewTaskDescriptionError] = useState("");

  const onNewTaskDescriptionChange = (newName: string) => {
    setNewTaskDescription(newName);
    setNewTaskDescriptionError("");
  };

  const onAddTaskModalOpen = () => {
    setIsAddTaskModalOpen(true);
    setNewTaskDescription("");
    setNewTaskDescriptionError("");
  };

  const onAddTaskModalCancel = () => {
    setIsAddTaskModalOpen(false);
  };

  const onAddTaskModalConfirm = () => {
    if (!newTaskDescription) {
      setNewTaskDescriptionError("Task must not be empty");
      return;
    }
    onAddTask();
    setIsAddTaskModalOpen(false);
  };

  const onEditTaskOpen = (index: number) => {
    setTaskIndexBeingEdited(index);
    setNewTaskDescription(trip!.tasks[index].description);
    setNewTaskDescriptionError("");
  };

  const onEditTaskModalCancel = () => {
    setTaskIndexBeingEdited(-1);
  };

  const onEditTaskModalConfirm = () => {
    if (!newTaskDescription) {
      setNewTaskDescriptionError("Task must not be empty");
      return;
    }
    onUpdateTaskDescription(taskIndexBeingEdited, newTaskDescription);
    setTaskIndexBeingEdited(-1);
  };

  const onAddTask = () => {
    const newTasks = [...trip!.tasks, { description: newTaskDescription, completed: false }];
    handleUpdateTripTasks(trip!.id, newTasks);
  };

  const onUpdateTaskDescription = (index: number, newDescription: string) => {
    const newTasks = [...trip!.tasks];
    newTasks[index].description = newDescription;
    handleUpdateTripTasks(id!, newTasks);
  };

  const onUpdateTaskCompleted = (index: number) => {
    const newTasks = [...trip!.tasks];
    newTasks[index].completed = !newTasks[index].completed;
    handleUpdateTripTasks(id!, newTasks);
  };

  const onDeleteTask = (taskIndex: number) => {
    const newTasks = trip!.tasks.filter((_, index) => index !== taskIndex);
    handleUpdateTripTasks(trip!.id, newTasks);
  };

  //FIRST TRIP LOGIC

  const [runTour, setRunTour] = useState(false);
  const [hasRunTour, setHasRunTour] = useState(false);

  const [steps] = useState<Step[]>([
    {
      target: "body",
      placement: "center",
      title: "Step 1 of 3",
      content: "This page tracks everything you will need to book your trip with confidence.",
      disableBeacon: true,
    },
    {
      target: ".menu-icon-button",
      placement: "bottom",
      title: "Step 2 of 3",
      content: "Open your itinerary in edit-mode to make changes with simple drag-and-drop operations.",
    },
    {
      target: ".task-section",
      placement: "bottom",
      title: "Step 3 of 3",
      content:
        "These tasks have been automatically generated. Add any that we missed and check them off as you book your trip.",
      locale: { last: "Done" },
    },
  ]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setRunTour(false);
    }
  };

  //RECOMMENDED TRIP LOGIC

  const isRecommended = trip?.id.substring(0, 11) === "recommended";
  const [runRecommendedTour, setRunRecommendedTour] = useState(isRecommended && !hasRunRecommendedTour);

  const [recommendedSteps] = useState<Step[]>([
    {
      target: ".add-trip-button",
      placement: "bottom",
      title: "This is just a preview",
      content: "To access all features and customize the itinerary, please add it to your account.",
      disableBeacon: true,
      locale: { last: "Okay" },
    },
  ]);

  const handleRecommendedJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setRunRecommendedTour(false);
      setHasRunRecommendedTour(true);
    }
  };

  return trip === undefined ? (
    <div>Loading</div>
  ) : (
    <Box sx={{ padding: 2 }}>
      <Joyride
        continuous
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Joyride
        continuous
        run={runRecommendedTour}
        steps={recommendedSteps}
        callback={handleRecommendedJoyrideCallback}
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Dialog open={isCopyTripModalOpen} onClose={onCopyTripCancel} fullWidth>
        <DialogTitle>Copy</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Trip Name"
            type="text"
            fullWidth
            variant="standard"
            value={newTripName}
            onChange={(e) => onNewTripNameChange(e.target.value)}
            error={newTripNameError !== ""}
            helperText={newTripNameError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCopyTripCancel}>Cancel</Button>
          <Button onClick={onCopyTripConfirm}>Copy</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteTripModalOpen} onClose={() => setIsDeleteTripModalOpen(false)} fullWidth>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete? There is no way to recover a trip after it has been deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteTripModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onDeleteTrip} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isShareTripModalOpen} onClose={() => setIsShareTripModalOpen(false)} fullWidth>
        <DialogTitle>Share Trip</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {trip?.shared
              ? "Sharing is currently enabled for this trip. Anyone with the link can view this trip in read-only mode."
              : "Sharing is currently disabled for this trip. Enable sharing to generate a shareable link. Anyone with the link can view this trip in read-only mode."}
          </DialogContentText>
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Button variant="contained" color={trip?.shared ? "error" : "primary"} onClick={onToggleShare}>
              {trip?.shared ? "Disable Sharing" : "Enable Sharing"}
            </Button>
          </Box>
          {trip?.shared && (
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                value={`https://tripbook.ai/trip/${id}`}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                <CopyToClipboard text={`https://tripbook.ai/trip/${id}`}>
                  <Button variant="outlined" startIcon={<ContentCopyIcon />}>
                    Copy Link
                  </Button>
                </CopyToClipboard>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsShareTripModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddTaskModalOpen} onClose={onAddTaskModalCancel} fullWidth>
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Task"
            type="text"
            fullWidth
            variant="standard"
            value={newTaskDescription}
            onChange={(e) => onNewTaskDescriptionChange(e.target.value)}
            error={newTaskDescriptionError !== ""}
            helperText={newTaskDescriptionError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddTaskModalCancel}>Cancel</Button>
          <Button onClick={onAddTaskModalConfirm}>Add task</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={taskIndexBeingEdited >= 0} onClose={onEditTaskModalCancel} fullWidth>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Task"
            type="text"
            fullWidth
            variant="standard"
            value={newTaskDescription}
            onChange={(e) => onNewTaskDescriptionChange(e.target.value)}
            error={newTaskDescriptionError !== ""}
            helperText={newTaskDescriptionError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onEditTaskModalCancel}>Cancel</Button>
          <Button onClick={onEditTaskModalConfirm}>Update</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", opacity: isDisabled ? "50%" : "100%" }}>
          {trip.name}
        </Typography>
        {isDisabled ? (
          <Tooltip title={!isAuthenticated ? "Sign in to add trip" : ""}>
            <span>
              <Button
                className="add-trip-button"
                variant="contained"
                color="primary"
                onClick={onAddRecommendedTrip}
                startIcon={<AddCircleOutlineIcon />}
                disabled={!isAuthenticated}
              >
                Add to My Trips
              </Button>
            </span>
          </Tooltip>
        ) : (
          <div>
            <IconButton
              className="menu-icon-button"
              aria-label="settings"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
              <div>
                <MenuItem
                  onClick={() => {
                    onEditTrip();
                    handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onCopyTripOpen();
                    handleClose();
                  }}
                >
                  Copy
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsShareTripModalOpen(true);
                    handleClose();
                  }}
                >
                  Share
                </MenuItem>
                <Tooltip title="Coming soon" placement={"left"}>
                  <span>
                    <MenuItem onClick={() => undefined} disabled>
                      Invite
                    </MenuItem>
                  </span>
                </Tooltip>
                <Tooltip title="Coming soon" placement={"left"}>
                  <span>
                    <MenuItem onClick={() => undefined} disabled>
                      Book
                    </MenuItem>
                  </span>
                </Tooltip>
                <MenuItem
                  onClick={() => {
                    setIsDeleteTripModalOpen(true);
                    handleClose();
                  }}
                >
                  Delete
                </MenuItem>
              </div>
            </Menu>
          </div>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper} elevation={3} sx={{ opacity: isDisabled ? "50%" : "100%" }}>
          <Table aria-label="itinerary table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: theme.palette.grey[300] }}>
                  <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: theme.palette.grey[300] }}>
                  <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>
                    Stop
                  </Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: theme.palette.grey[300] }}>
                  <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>
                    Activities
                  </Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: theme.palette.grey[300] }}>
                  <Typography variant="body1" sx={{ color: "black", fontWeight: "bold" }}>
                    Accommodation
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trip.stops.flatMap((stop, ix1) =>
                stop.days.map((day, ix2) => {
                  const dayDate = trip.startDate.add(dayIndex, "day").format("ddd MMM D, YYYY");
                  dayIndex++;
                  return (
                    <TableRow key={`${ix1}${ix2}`} hover>
                      <TableCell style={{ pointerEvents: "none", minWidth: 150 }}>
                        <Typography variant="body2">{dayDate}</Typography>
                      </TableCell>
                      <TableCell style={{ pointerEvents: "none", minWidth: 150 }}>
                        <Typography variant="body2">{stop.name}</Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          pointerEvents: "none",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <Typography variant="body2">{day.description}</Typography>
                      </TableCell>
                      <TableCell style={{ pointerEvents: "none" }}>
                        <Typography variant="body2">
                          {day.accommodation === null ? "N/A" : day.accommodation}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Box className="task-section" sx={{ display: "flex", flexDirection: "column" }}>
            {trip.tasks.map((task, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={task.completed}
                      onChange={() => onUpdateTaskCompleted(index)}
                      color="primary"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "inherit" } }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ opacity: isDisabled ? "50%" : "100%" }}>
                      {task.description}
                    </Typography>
                  }
                  disabled={isDisabled}
                />
                <IconButton onClick={() => onEditTaskOpen(index)} size="small" disabled={isDisabled}>
                  <EditIcon style={{ fontSize: "inherit" }} />
                </IconButton>
                <IconButton onClick={() => onDeleteTask(index)} size="small" disabled={isDisabled}>
                  <CloseIcon style={{ fontSize: "inherit" }} />
                </IconButton>
              </Box>
            ))}
            <Box sx={{ display: "flex" }}>
              <Button onClick={onAddTaskModalOpen} disabled={isDisabled} startIcon={<AddCircleOutlineIcon />}>
                Add Task
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewTrip;
