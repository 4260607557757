// src/Tripbook.tsx

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useTripbook } from "../hooks/useTripbook";
import DefineTrip from "./DefineTrip";
import EditTrip from "./EditTrip";
import Footer from "./Footer";
import Home from "./Home";
import NavBar from "./NavBar";
import ViewTrip from "./ViewTrip";

export const APP_NAME_SHORT = "Tripbook";
export const APP_NAME = `${APP_NAME_SHORT} AI`;

interface TripbookProps {}

const Tripbook: React.FC<TripbookProps> = () => {
  const {
    tripName,
    setTripName,
    startDate,
    setStartDate,
    tripDescription,
    setTripDescription,
    setStops,
    isEditing,
    setIsEditing,
    trips,
    //TODO: merge this with trips, using isRecommended parameter?
    recommendedTrips,
    resetState,
    handleCreateTrip,
    handleUpdateTripDetails,
    handleUpdateTripTasks,
    handleRemoveTrip,
  } = useTripbook();

  const [hasRunRecommendedTour, setHasRunRecommendedTour] = useState(false);

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const isAuthenticated = authStatus === "authenticated";

  useEffect(() => {
    document.title = APP_NAME_SHORT;
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        <NavBar
          tripName={tripName}
          tripDescription={tripDescription}
          isEditing={isEditing}
          resetState={resetState}
          isAuthenticated={isAuthenticated}
        />
        <Routes>
          <Route
            path="/"
            element={<Home trips={trips} recommendedTrips={recommendedTrips} isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/define"
            element={
              <DefineTrip
                tripName={tripName}
                setTripName={setTripName}
                startDate={startDate}
                setStartDate={setStartDate}
                tripDescription={tripDescription}
                setTripDescription={setTripDescription}
                setStops={setStops}
                handleCreateTrip={handleCreateTrip}
                resetState={resetState}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/edit/:id"
            element={
              <EditTrip
                trips={trips}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                handleUpdateTripDetails={handleUpdateTripDetails}
              />
            }
          />
          <Route
            path="/edit/new/:id"
            element={
              <EditTrip
                trips={trips}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                handleUpdateTripDetails={handleUpdateTripDetails}
                isNewTrip={true}
                //TODO: Figure out a cleaner way to define isFirstTrip here and below
                isFirstTrip={trips.filter((t) => t.id.substring(0, 11) !== "recommended").length === 0}
              />
            }
          />
          <Route
            path="/trip/:id"
            element={
              <ViewTrip
                trips={[...trips, ...recommendedTrips]}
                handleUpdateTripDetails={handleUpdateTripDetails}
                handleUpdateTripTasks={handleUpdateTripTasks}
                handleCreateTrip={handleCreateTrip}
                handleDeleteTrip={handleRemoveTrip}
                isAuthenticated={isAuthenticated}
                hasRunRecommendedTour={hasRunRecommendedTour}
                setHasRunRecommendedTour={setHasRunRecommendedTour}
              />
            }
          />
          <Route
            path="/trip/new/:id"
            element={
              <ViewTrip
                trips={[...trips, ...recommendedTrips]}
                handleUpdateTripDetails={handleUpdateTripDetails}
                handleUpdateTripTasks={handleUpdateTripTasks}
                handleCreateTrip={handleCreateTrip}
                handleDeleteTrip={handleRemoveTrip}
                isAuthenticated={isAuthenticated}
                hasRunRecommendedTour={hasRunRecommendedTour}
                setHasRunRecommendedTour={setHasRunRecommendedTour}
                isFirstTrip={trips.filter((t) => t.id.substring(0, 11) !== "recommended").length === 1}
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </Router>
    </Box>
  );
};

export default Tripbook;
